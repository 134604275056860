import { Injectable, inject } from '@angular/core';

import { GetAllDepartmentGQL, GetDepartmentGQL } from '@alan-apps/data-access';
import {
  BaseHttpService,
  cache,
  ICache,
  ONE_YEAR,
} from '@nghedgehog/angular-ui';

@Injectable({
  providedIn: 'root',
})
export class DepartmentService implements ICache {
  private _http = inject(BaseHttpService);
  private getDepartmentGQL = inject(GetDepartmentGQL);
  private getAllDepartmentGQL = inject(GetAllDepartmentGQL);
  readonly storageKey = 'DepartmentService';

  get(id: string) {
    return this._http.apollo(this.getDepartmentGQL.fetch({ id }));
  }

  @cache({
    timeMs: ONE_YEAR,
  })
  all({
    info: withInfo = false,
    root = true,
  }: { info?: boolean; root?: boolean } = {}) {
    return this._http.apollo(
      this.getAllDepartmentGQL.fetch({ root, withInfo }),
    );
  }
}
